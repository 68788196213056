<ng-content class="text-center" *ngIf="emailSent"> </ng-content>
<h4 class="text-center" *ngIf="!emailSent">
  Enter the invitee email so they can create an account
</h4>
<form [formGroup]="formGroup" *ngIf="!emailSent">
  <mat-form-field appearance="outline">
    <mat-icon matPrefix>email</mat-icon>
    <input matInput type="email" formControlName="email" placeholder="Email" />
  </mat-form-field>
  <evo-error name="email" [form]="formGroup"></evo-error>

  <button
    color="primary"
    class="xl"
    (click)="invite(formGroup.value)"
    [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine"
    mat-raised-button
    *ngIf="!(busy$ | async)"
  >
    Invite
  </button>
  <div *ngIf="busy$ | async" class="row">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
</form>
