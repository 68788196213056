import { IIdentityToken } from '../interfaces/identity-token.interface';
import { IParsedClaims } from '../interfaces/parsed-claims.interface';
import { fromBooleanString } from './from-boolean-string.function';
import { fromCsvString } from './from-csv-string.function';

export function parseClaims(decoded?: IIdentityToken): IParsedClaims {
  if (decoded) {
    const parsed: IParsedClaims = {
      ...decoded,
      apps: fromCsvString(decoded.apps),
      facs: fromCsvString(decoded.facs),
      hasReportsOnly: fromBooleanString(decoded.hasReportsOnly),
      isAdmin: fromBooleanString(decoded.isAdmin),
      isAnon: fromBooleanString(decoded.isAnon),
      isCompAssignee: fromBooleanString(decoded.isCompAssignee),
      isCompOfficer: fromBooleanString(decoded.isCompOfficer),
      isCompReporter: fromBooleanString(decoded.isCompReporter),
      isQuota: fromBooleanString(decoded.isQuota),
      isSysAdmin: fromBooleanString(decoded.isSysAdmin),
      orgId: decoded.orgId || '',
      reports: fromCsvString(decoded.reports),
      userId: decoded.sub,
    };

    return parsed;
  } else {
    console.warn(`parseClaims: ID token missing`);
    return {} as any;
  }
}
