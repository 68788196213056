export enum GlobalAttachmentTypeEnum {
  DOXAMATION_BATCH = 'DOXAMATION_BATCH',
  DOXAMATION_INSTANCE = 'DOXAMATION_INSTANCE',
  DOXAMATION_TEMPLATE = 'DOXAMATION_TEMPLATE',
  LAWBOT_UPLOAD = 'LAWBOT_UPLOAD',
  MESSAGE = 'MESSAGE',
  NOTE = 'NOTE',
  ORG_LOGO = 'ORG_LOGO',
  REPORT_RENDERING = 'REPORT_RENDERING',
  REPORTER_SUBMISSION = 'REPORTER_SUBMISSION',
}
