import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ClaimService } from '../services/claim.service';

@Directive({ selector: '[isCompOfficer]' })
export class IsCompOfficerDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private claimSvc: ClaimService,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set isCompOfficer(condition: boolean) {
    const show = !!condition === this.claimSvc.get().isCompOfficer;

    if (show) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
