import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { DefaultApi, Invitation } from '@evo/generated/account';
import { formatRegexes } from '@evo/ui/angular-serializable-forms';
import { BaseComponent } from '../../base-classes/base.component';
import { SnackService } from '../../services/snack.service';

@Component({
  selector: 'evo-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent extends BaseComponent implements OnInit {
  @Input() api: DefaultApi;
  @Input() orgId: string;
  @Output() registered = new EventEmitter();
  formGroup!: FormGroup;
  userId$$ = new BehaviorSubject<string|undefined>(undefined);
  userId$: Observable<string> = this.userId$$.asObservable().pipe(
    filter(userId => !!userId),
    tap(userId => {
      this.showContactInfoForm = false;
      this.showVerificationForm = false;
      this.showPasswordForm = false;
      this.userId = userId;
    }),
    tap(userId => this.registered.emit(userId)),
  ) as any;
  name: string;
  showContactInfoForm = true;
  showVerificationForm = false;
  formGroupVerify: FormGroup;
  formGroupPassword: FormGroup;
  showPasswordForm = false;
  emailSent = false;
  email: string;
  invitation: Invitation;
  userId: string | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(formatRegexes.email),
        ],
      }),
      phone: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(formatRegexes.phone),
        ],
      }),
      isAnon: new FormControl(null),
    });

    this.formGroupVerify = this.formBuilder.group({
      sms: [null, Validators.required],
      email: [null, Validators.required]
    });

    const validators = {
      validators: [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(formatRegexes.passwordLower),
        Validators.pattern(formatRegexes.passwordUpper),
        Validators.pattern(formatRegexes.passwordNumber),
        Validators.pattern(formatRegexes.passwordSpecial),
      ],
    };

    this.formGroupPassword = this.formBuilder.group({
      password: new FormControl(null, validators),
      confirm: new FormControl(null, validators),
    });
  }

  async signup({ email,  phone, isAnon }: { email: string,  phone: string, isAnon: boolean }) {
    this.busy$$.next(true);

    try {
      await this.api.userInvitationControllerPostLinkless({isAnon, email, phone, orgId: this.orgId })
      this.emailSent = true;
      this.email = email;
      this.showContactInfoForm = false;
      this.showVerificationForm = true;
    } catch (error: any) {
      if (error.response?.status === 409) {
        this.snackBar.open(
          `Looks like you already have an account. Try logging in with ${email}.`
        );
      } else {
        this.snackBar.open(`That didn't work. Please try again.`);
      }
    }

    this.busy$$.next(false);
  }

  async verify({email, sms}: {email: string, sms: string}) {
    this.busy$$.next(true);
    try {
      this.invitation = (await this.api.userInvitationControllerPutLinkless(email, { code: sms })).data;
      this.showContactInfoForm = false;
      this.showVerificationForm = false;
      this.showPasswordForm = true;
    } catch (error: any) {
      this.handleError(error);
    }
    this.busy$$.next(false);
  }

  async savePassword({password, confirm}: {password: string, confirm: string}) {
    this.busy$$.next(true);
    try {
      const registration: Invitation = (await this.api.userInvitationControllerPutPassword(this.invitation.hash, { password, confirm })).data;
      this.showContactInfoForm = false;
      this.showVerificationForm = false;
      this.showPasswordForm = false;
      this.userId$$.next(registration.userId);
    } catch (error: any) {
      this.handleError(error);
    }
    this.busy$$.next(false);
  }

  handleError(e: Error) {
    console.warn(e);
    this.busy$$.next(false);
    this.snackBar.oops();
  }
}
