import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './file-upload-modal.component.html',
})
export class FileUploadModalComponent {
  formData: FormData | undefined;
  output: { files: File[]; formData: FormData };

  onUpload($event: FormData) {
    this.formData = $event;
  }

  onAttach($event: any) {
    this.output = $event;
  }

  finalize() {
    this.dialogRef.close(this.output);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<any>
  ) {}
}
