import { Injectable } from '@angular/core';
import { DefaultApi, GlobalNote } from '@evo/generated/reports';
import { BehaviorSubject, map } from 'rxjs';
import { count } from '../functions/count.function';
import { isNotZero } from '../functions/is-not-zero.function';
import { MultipartContentClientService } from './multipart-content-client.service';

@Injectable()
export class GlobalNotesService {
  private notes$$ = new BehaviorSubject<GlobalNote[]>([]);
  notes$ = this.notes$$.asObservable();
  notesCount$ = this.notes$.pipe(map(count));
  notesPresent$ = this.notesCount$.pipe(map(isNotZero));

  constructor(
    private api: DefaultApi,
    private multipartContentClientSvc: MultipartContentClientService
  ) {}

  async loadNotesForEntity(entityId: string) {
    const notes = (await this.api.globalNotesControllerGetByEntityId(entityId))
      .data;
    this.notes$$.next(notes);
  }

  async createNote(note: GlobalNote) {
    const notes = (await this.api.globalNotesControllerCreate(note)).data;
    this.notes$$.next(notes);
  }

  async createNoteWithAttachments(note: GlobalNote, formData?: FormData) {
    if (formData) {
      await this.multipartContentClientSvc.postFilesAndData(
        formData,
        `global-notes/with-attachment/${note.appId}`,
        note
      );
      await this.loadNotesForEntity(note.entityId);
    } else {
      await this.createNote(note);
    }
  }
}
