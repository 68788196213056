export enum RoleEnum {
  ADMISSION_USER_FEDERAL = 'ADMISSION_USER_FEDERAL',
  ADMISSION_USER_MICHIGAN = 'ADMISSION_USER_MICHIGAN',
  ADMISSION_USER_OHIO = 'ADMISSION_USER_OHIO',
  APP_OWNER = 'APP_OWNER',
  APP_USER = 'APP_USER',
  COMPLIANCE_OFFICER = 'COMPLIANCE_OFFICER', // sees reports for all facs
  COMPLIANCE_REPORTER = 'COMPLIANCE_REPORTER', // whoever files the report... they'll be limited to specific reports they file... non-quota
  COMPLIANCE_USER = 'COMPLIANCE_USER', // the same as compliance officer except limited to certain reports, categories, facilities... no notifications for new reports
  MEDICAID_APPLICANT = 'MEDICAID_APPLICANT',
  MEDICAID_SPECIALIST = 'MEDICAID_SPECIALIST',
  SYSTEM_OWNER = 'SYSTEM_OWNER',
}
