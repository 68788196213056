import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base-classes/base.component';
import { FlyoutService, FlyoutType } from '../../services/flyout.service';
import { TAppRoute } from '../../types/app-route.type';

@Component({
  selector: 'evo-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent extends BaseComponent implements OnDestroy, OnInit {
  @Input() routes: TAppRoute[];
  @Input() flyoutData: any;
  @Input() hasFlyout = false;
  @Input() shouldHaveBackdrop = false;
  @ViewChild('flyout') flyout: any;

  type: FlyoutType;
  flyoutEnum = FlyoutType;
  task: any;

  constructor(private flyoutSvc: FlyoutService) {
    super();
  }

  ngOnInit() {
    this.flyoutSvc.openFlyout.subscribe(res => {
      this.flyout.toggle()
    });
  }

  closeFlyout() {
    this.flyout.close();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
