import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'evo-primary-button',
  templateUrl: './primary-button.component.html',
})
export class PrimaryButtonComponent {
  @Output() click = new EventEmitter();
  @Input() disabled: boolean;
}
