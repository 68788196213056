import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DefaultApi, GlobalNotification } from '@evo/generated/reports';
import { AppEnum } from '@evo/iso/common';
import { BehaviorSubject, map } from 'rxjs';
import { count } from '../functions/count.function';
import { isNotZero } from '../functions/is-not-zero.function';

@Injectable()
export class GlobalNotificationsService {
  appId: AppEnum;
  notifications$$ = new BehaviorSubject<Partial<GlobalNotification>[]>([]);

  notifications$ = this.notifications$$.asObservable();
  notificationsCount$ = this.notifications$.pipe(map(count));
  notificationsPresent$ = this.notificationsCount$.pipe(map(isNotZero));

  notificationsUndismissed$ = this.notifications$.pipe(
    map((ns) => ns.filter((n) => !n.dismissed))
  );
  notificationsUndismissedCount$ = this.notificationsUndismissed$.pipe(
    map(count)
  );
  notificationsUndismissedPresent$ = this.notificationsUndismissedCount$.pipe(
    map(isNotZero)
  );

  notificationsUnread$ = this.notificationsUndismissed$.pipe(
    map((ns) => ns.filter((n) => !n.read))
  );
  notificationsUnreadCount$ = this.notificationsUnread$.pipe(map(count));
  notificationsUnreadPresent$ = this.notificationsUnreadCount$.pipe(
    map(isNotZero)
  );

  notificationsDismissed$ = this.notifications$.pipe(
    map((ns) => ns.filter((n) => !!n.dismissed))
  );
  notificationsDismissedCount$ = this.notificationsDismissed$.pipe(map(count));
  notificationsDismissedPresent$ = this.notificationsDismissedCount$.pipe(
    map(isNotZero)
  );

  notificationsRead$ = this.notificationsUndismissed$.pipe(
    map((ns) => ns.filter((n) => n.read))
  );
  notificationsReadCount$ = this.notificationsRead$.pipe(map(count));
  notificationsReadPresent$ = this.notificationsReadCount$.pipe(map(isNotZero));

  constructor(
    private api: DefaultApi,
    private router: Router,
    private dialog: MatDialog
  ) {}

  async getAppNotifications(appId: AppEnum) {
    this.appId = appId;
    const notifications = (
      await this.api.globalNotificationsControllerGetUsersNotifications(appId)
    ).data;
    this.notifications$$.next(notifications);
  }

  async markNotificationRead(n: GlobalNotification) {
    await this.api.globalNotificationsControllerMarkAsRead(n.id);
    await this.getAppNotifications(n.appId as AppEnum);
  }

  async markNotificationUnread(n: GlobalNotification) {
    await this.api.globalNotificationsControllerMarkAsUnread(n.id);
    await this.getAppNotifications(n.appId as AppEnum);
  }

  async navigateTo(notification: GlobalNotification) {
    if (notification.link) {
      this.router.navigateByUrl(notification.link as string);
    }
    await this.markNotificationRead(notification);
    this.dialog.closeAll();
  }

  async dismissNotification(n: GlobalNotification) {
    await this.api.globalNotificationsControllerDelete(n.id);
    await this.getAppNotifications(n.appId as AppEnum);
  }

  async restoreNotification(n: GlobalNotification) {
    await this.api.globalNotificationsControllerRestore(n.id);
    await this.getAppNotifications(n.appId as AppEnum);
  }

  async dismissAllNotification(appId: AppEnum) {
    await this.api.globalNotificationsControllerDeleteAllUsersNotifications();
    await this.getAppNotifications(appId || this.appId);
    this.dialog.closeAll();
  }
}
