import { DefaultApi } from '@evo/generated/revenue';
import { axiosInstanceFactory } from './axios-instance.factory';
import { Config } from './config.class';
import { EvoCookieService } from '../services/evo-cookie.service';

export function revenueApiProvider(
  config: Config,
  cookieService: EvoCookieService
) {
  return {
    provide: DefaultApi,
    useFactory: () =>
      new DefaultApi(
        { basePath: config.api } as any,
        config.api,
        axiosInstanceFactory(cookieService, config.uiUrlAccount)
      ),
  };
}
