import { Component } from '@angular/core';

@Component({
  selector: 'evo-row',
  template: `<div
    class="whitespace-pre-wrap flex flex-1 flex-row flex-wrap justify-stretch grow-0 shrink-0 width-full"
  >
    <ng-content></ng-content>
  </div>`,
})
export class RowComponent {}
