export interface IStripeWebhookEvent<TData> {
  id: string;
  apiVersion?: string;
  created: number;
  data: TData;
  livemode: boolean;
  pendingWebhooks: number;
  request: {
    id?: string;
    idempotencyKey?: string;
  };
  type: string;
}
