export interface IParsedClaims {
  apps: string[];
  facs: string[];
  hasReportsOnly: boolean;
  isAdmin: boolean;
  isAnon: boolean;
  isCompAssignee: boolean;
  isCompOfficer: boolean;
  isCompReporter: boolean;
  isQuota: boolean;
  isSysAdmin: boolean;
  orgId: string;
  reports: string[];
  userId: string;
}
