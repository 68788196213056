<ng-container *ngIf="!(userId$ | async) && orgId">
  <ng-container *ngIf="showContactInfoForm">
    <form class="mt-2" [formGroup]="formGroup">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>email</mat-icon>
        <input matInput type="text" formControlName="email" placeholder="Email" />
      </mat-form-field>

      <evo-error name="email" [form]="formGroup"></evo-error>

      <mat-form-field appearance="outline">
        <mat-icon matPrefix>phone</mat-icon>
        <input matInput type="text" formControlName="phone" placeholder="Mobile phone" />
      </mat-form-field>

      <evo-error name="phone" [form]="formGroup"></evo-error>

      <div class="pb-2">
        <mat-checkbox color="primary" formControlName="isAnon">
          <mat-label>Protect my identity</mat-label>
        </mat-checkbox>
      </div>

      <button color="primary" class="xl" (click)="signup(formGroup.value)"
        [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine" mat-raised-button
        *ngIf="!(busy$ | async)">
        Register
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showVerificationForm">
    <form class="mt-2" [formGroup]="formGroupVerify">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>pin</mat-icon>
        <input matInput type="text" formControlName="sms" placeholder="SMS Code" />
      </mat-form-field>
      <evo-error name="sms" [form]="formGroupVerify"></evo-error>

      <mat-form-field appearance="outline">
        <mat-icon matPrefix>pin</mat-icon>
        <input matInput type="text" formControlName="email" placeholder="Email Code" />
      </mat-form-field>
      <evo-error name="email" [form]="formGroupVerify"></evo-error>

      <button
        color="primary"
        class="xl"
        *ngIf="!(busy$ | async)"
        (click)="verify(formGroupVerify.value)"
        [disabled]="
          (busy$ | async) || formGroupVerify.invalid || formGroupVerify.pristine
        "
        mat-raised-button
      >
        Validate
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showPasswordForm">
    <form class="mt-2" [formGroup]="formGroupPassword">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input
          matInput
          type="password"
          formControlName="password"
          placeholder="Password"
        />
      </mat-form-field>
      <evo-error name="password" [form]="formGroupPassword"></evo-error>

      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input
          matInput
          type="password"
          formControlName="confirm"
          placeholder="Confirm"
        />
      </mat-form-field>
      <evo-error name="confirm" [form]="formGroupPassword"></evo-error>

      <button
        color="primary"
        class="xl"
        (click)="savePassword(formGroupPassword.value)"
        [disabled]="(busy$ | async) || formGroupPassword.invalid || formGroupPassword.pristine"
        mat-raised-button
        *ngIf="!(busy$ | async)"
      >
        Finish Account Setup
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>
</ng-container>
<ng-template #registered>
  <p><mat-icon>check</mat-icon> You have completed registration. Your login is {{invitation.email}}</p>
</ng-template>