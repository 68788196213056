<div class="flex-1 flex flex-row justify-between items-center">
  <div class="mat-title">Notification Center</div>

  <ng-container
    *ngIf="notificationSvc.notificationsUndismissedPresent$ | async; else date"
  >
    <button
      class="mb-2"
      mat-stroked-button
      (click)="notificationSvc.dismissAllNotification()"
    >
      <mat-icon>delete_sweep</mat-icon> All
    </button>
  </ng-container>

  <ng-template #date>
    <div class="mat-title">
      {{ now | date }}
    </div>
  </ng-template>
</div>

<mat-divider></mat-divider>
<ng-container *ngIf="notificationSvc.notificationsPresent$ | async; else none">
  <mat-tab-group>
    <mat-tab
      [label]="
        'Unread (' + (notificationSvc.notificationsUnreadCount$ | async) + ')'
      "
    >
      <evo-notification-list
        [notifications]="notificationSvc.notificationsUnread$ | async"
        [enableDeleting]="true"
      ></evo-notification-list>
    </mat-tab>

    <mat-tab
      [label]="
        'All (' + (notificationSvc.notificationsUndismissedCount$ | async) + ')'
      "
    >
      <evo-notification-list
        [notifications]="notificationSvc.notificationsUndismissed$ | async"
        [enableDeleting]="true"
      ></evo-notification-list>
    </mat-tab>

    <mat-tab
      [label]="
        'Deleted (' +
        (notificationSvc.notificationsDismissedCount$ | async) +
        ')'
      "
    >
      <evo-notification-list
        emptyMessage="No notifications have been deleted."
        [notifications]="notificationSvc.notificationsDismissed$ | async"
        [enableDeleting]="false"
      ></evo-notification-list>
    </mat-tab>
  </mat-tab-group>
</ng-container>
<ng-template #none>
  <p class="mat-body-2 text-center p-4">You have no notifications.</p>
</ng-template>
