import { IGlobalNotificationCore } from './global-notification-core.interface';

export interface IGlobalNotification<TData = any>
  extends IGlobalNotificationCore {
  link: string;
  title: string;
  description: string;
  createdAt: Date;
  entityId: string;
  read: boolean;
  data: TData;
  dismissed: boolean;
}
