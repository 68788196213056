import { Injectable } from '@angular/core';
import { Config } from '../providers/config.class';
import { CookieService } from 'ngx-cookie-service';
import { decode } from '../functions/decode.function';

@Injectable({ providedIn: 'root' })
export class EvoCookieService {
  authCookieName = 'evolve_auth';
  emailCookieName = 'evolve_email';
  idCookieName = 'compjwt';

  constructor(private cookieService: CookieService, private config: Config) {}

  getAuthToken() {
    return this.getCookie(this.authCookieName);
  }

  getIdToken() {
    return this.getCookie(this.idCookieName);
  }

  setEmail(email: string) {
    this.setCookie(this.emailCookieName, email);
  }

  getEmail() {
    return this.getCookie(this.emailCookieName);
  }

  deleteEmail() {
    return this.deleteCookie(this.emailCookieName);
  }

  setSessionTokens(authToken: string, idToken: string) {
    this.setCookie(this.authCookieName, authToken, extractExp(authToken));
    this.setCookie(this.idCookieName, idToken, extractExp(idToken));
  }

  deleteSessionTokens() {
    this.deleteCookie(this.authCookieName);
    this.deleteCookie(this.idCookieName);
  }

  private deleteCookie(name: string) {
    this.cookieService.delete(name, '/', this.config.domain);
  }

  private getCookie(name: string) {
    return this.cookieService.get(name);
  }

  private setCookie(name: string, value: string, exp?: Date) {
    const expires = exp ? ` Expires='${exp.toUTCString()}';` : ``;
    document.cookie = `${name}=${value}; SameSite=Lax; Path=/; Domain=${this.config.domain};${expires}`;
  }
}

function extractExp(tokenString: string) {
  const { exp }: { exp: string } = decode(tokenString);
  return new Date(Number(exp) * 1000);
}
