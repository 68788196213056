import { AppEnum } from '../enums/app.enum';
import { ILineItemPriceInfo } from '../interfaces/line-item-price-info.interface';
import { calculateDiscount } from './calculate-discount.function';
import { calculatePriceInCents } from './calculate-price.function';

export function calculateAdmissionPrice(
  count: number,
  apps: string[],
  ratio = 1
) {
  let fed = 0;
  let state = 0;
  if (count >= 1 && count <= 2) {
    fed = 749;
    state = 999;
  } else if (count >= 3 && count <= 5) {
    fed = 999;
    state = 1249;
  } else if (count >= 6 && count <= 10) {
    fed = 1249;
    state = 1499;
  } else if (count >= 11 && count <= 20) {
    fed = 1499;
    state = 1749;
  } else if (count >= 21 && count <= 35) {
    fed = 1749;
    state = 1999;
  } else if (count >= 36) {
    fed = 1999;
    state = 2149;
  }

  let total = 0;
  let includesFed = false;
  const includedStates = [];

  if (apps.includes(AppEnum.ADMFED)) {
    total += fed;
    includesFed = true;
  }

  if (apps.includes(AppEnum.ADM)) {
    total += state;
    includedStates.push(AppEnum.ADM);
  }

  if (apps.includes(AppEnum.ADMMIC)) {
    total += state;
    includedStates.push(AppEnum.ADMMIC);
  }

  let effectiveRatio = 1;

  if (includesFed) {
    if (includedStates.length === 1) {
      effectiveRatio = 0.9;
    } else if (includedStates.length === 2) {
      effectiveRatio = 0.8;
    }
  }

  effectiveRatio = Math.min(effectiveRatio, ratio);

  const lineItemPriceInfo: ILineItemPriceInfo = {
    price: calculatePriceInCents(total, effectiveRatio),
    discount: calculateDiscount(effectiveRatio),
    apps,
  } as ILineItemPriceInfo;

  return lineItemPriceInfo;
}
