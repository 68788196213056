<div>
  <mat-card class="data-card">
    <mat-card-header>
      <mat-card-title>{{ data?.title || 'JSON Detail' }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <pre>{{ data | json }}</pre>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" mat-dialog-close>Close</button>
    </mat-card-actions>
  </mat-card>
</div>
