<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    fixedTopGap="64"
    [mode]="'side'"
    [opened]="true"
  >
    <div class="mat-list-item row" *ngFor="let r of routes">
      <a
        mat-fab
        *ngIf="r.icon && r.path"
        routerLinkActive="warn"
        [routerLink]="r.path"
        ><mat-icon>{{ r.icon }}</mat-icon></a
      >
    </div>
    <!-- </mat-nav-list> -->
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <mat-sidenav 
    class="flyout"
    *ngIf="hasFlyout"
    #flyout 
    mode="side" 
    [disableClose]="false"
    position="end">
    <ng-content></ng-content>
  </mat-sidenav>
</mat-sidenav-container>

