import { Component, Input, OnInit } from '@angular/core';
import { GlobalAttachment } from '@evo/generated/reports';

@Component({
  selector: 'evo-entity-attachments',
  templateUrl: './entity-attachments.component.html',
})
export class EntityAttachmentsComponent {
  @Input() attachments: GlobalAttachment[];
}
