<button
  [matBadge]="(notificationSvc.notificationsUnreadCount$ | async) || ''"
  matBadgeColor="accent"
  [color]="
    (notificationSvc.notificationsUnreadPresent$ | async) ? 'primary' : 'accent'
  "
  mat-mini-fab
  (click)="openNotificationsDialog()"
>
  <mat-icon>notifications</mat-icon>
</button>
