import { BehaviorSubject, Subject, Subscription } from 'rxjs';

export class BaseComponent {
  busy$$ = new BehaviorSubject(false);
  busy$ = this.busy$$.asObservable();
  destroyed$$ = new Subject();
  destroyed$ = this.busy$$.asObservable();
  subscriptions: Subscription[] = [];

  unsubscribe () {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
