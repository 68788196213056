import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DefaultApi } from '@evo/generated/account';
import { GlobalNotificationsService } from '../services/global-notifications.service';

// Why all this jazz?
// The service lives in a shared-lib and uses a dep that is non-injectable in that context due to namespace collisions (aka multiple classes w/ same name)
// so that requires a useFactory expression.
// Since we are using a shared behavior subject in the service, the service must be a singleton;
// nonetheless, we still may be able to use providedIn: 'root' and eliminate the manual singleton-management.
// For now... this is fine.

export function globalNotificationsServiceProvider(
  api: any,
  cachedInstance: GlobalNotificationsService | undefined,
  cb: (createdInstance: GlobalNotificationsService) => void
) {
  return {
    provide: GlobalNotificationsService,
    deps: [api, Router, MatDialog],
    useFactory: (api: DefaultApi, router: Router, dialog: MatDialog) => {
      if (cachedInstance) {
        cb(cachedInstance);
        return cachedInstance;
      }

      const createdInstance = new GlobalNotificationsService(
        api as any,
        router,
        dialog
      );
      cb(createdInstance);
      return createdInstance;
    },
  };
}
