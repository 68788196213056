<div class="p-4 vh-100">
  <mat-card class="mat-elevation-z8 mw-440">
    <div class="flex flex-row justify-center">
      <evo-logo></evo-logo>
    </div>
    <div class="mt-2">
      <ng-content></ng-content>
    </div>
  </mat-card>
</div>
