export interface IUser {
    id: string;
    active: boolean;
    apps: string[];
    facs: string[];
    categories: string[];
    email: string;
    firstName: string;
    lastName: string;
    data: any;
    orgId: string;
    org: any;
    phone: string;
    createdByApp: string;
    createdAt: Date;
    updatedByApp: string;
    updatedAt: Date;
    isAdmin: boolean;
    complianceOrgId: number;
    isSysAdmin?: boolean;
    hasAllFacs?: boolean;
    isAnon?: boolean;
}
