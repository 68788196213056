<ng-container *ngFor="let route of routes">
  <ng-container *ngIf="route.icon && route.label">
    <a
      *ngIf="route.path"
      mat-menu-item
      [routerLink]="[route.path]"
      routerLinkActive="primary"
      [target]="route.targetBlank ? '_blank' : '_self'"
    >
      <mat-icon color="accent">{{ route.icon }}</mat-icon> {{ route.label }}
    </a>
    <a
      *ngIf="route.href"
      mat-menu-item
      [href]="[route.href]"
      [target]="route.targetBlank ? '_blank' : '_self'"
    >
      <mat-icon color="accent">{{ route.icon }}</mat-icon> {{ route.label }}
    </a>
  </ng-container>
</ng-container>
