import { CookieService } from 'ngx-cookie-service';
import { EvoCookieService } from '../services/evo-cookie.service';
import { Config } from './config.class';

export function cookieServiceFactory(
  config: Config,
  authCookieName?: string,
  idCookieName?: string
) {
  const cookieSvc = new EvoCookieService(
    new CookieService(document, 'browser'),
    config
  );

  if (authCookieName) {
    cookieSvc.authCookieName = authCookieName;
  }

  if (idCookieName) {
    cookieSvc.idCookieName = idCookieName;
  }

  return cookieSvc;
}
