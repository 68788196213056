import { Injectable } from '@angular/core';
import { Config, EvoCookieService } from '@evo/ui/common';
import { axiosInstanceFactory } from '../providers/axios-instance.factory';

@Injectable({ providedIn: 'root' })
export class MultipartContentClientService {
  constructor(
    private config: Config,
    private cookieService: EvoCookieService
  ) {}

  async postFilesAndData(
    formData: FormData,
    path: string,
    value: Record<any, any> | any[],
    key = '{}'
  ) {
    formData.append(key, JSON.stringify(value));

    return await this.postFiles(formData, path);
  }

  async postFiles(formData: FormData, path: string) {
    const axios = axiosInstanceFactory(
      this.cookieService,
      this.config.uiUrlAccount
    );
    const response = (
      await axios.post(`${this.config.api}/api/${path}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: this.cookieService.getAuthToken(),
          'x-identity-token': this.cookieService.getIdToken(),
        },
      })
    ).data;

    return response;
  }
}
