export interface INotification {
  id: number;
  userId: string;
  app: string;
  link: string;
  linkText: string;
  createdAt: Date;
  description: string;
  dismissed: boolean;
  reportId: number;
}

export interface INewNotification {
  id: string;
  userId: string;
  app: string;
  link: string;
  createdAt: Date;
  description: string;
  reportId: string;
}
