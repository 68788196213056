import { Routes } from "@angular/router";

const paramRegX = /:\w*/g;

export function url(routes: Routes, component: { name: string }, params?: Record<string, any>) {

    const suspects = routes.filter(r => r.component?.name === component.name);

    if (!suspects.length) {
        console.warn(`no route found for component ${component.name} route =>`, routes);
        return;
    }

    if (suspects.length > 1) {
        console.warn(`multiple routes found for component ${component.name} route =>`, routes);
        return;
    }

    const route = suspects[0].path;

    const expectedParams = route?.match(paramRegX)?.map(p => p.replace(':', ''));

    if (!expectedParams?.length) {
        if (params) {
            console.warn(`unexpected params provided for component ${component.name} route =>`, { params, route });
        }
        return route;
    }

    if (!params) {
        console.warn(`no params provided for component ${component.name} route =>`, { expectedParams, route });
        return;
    }

    const missingParams = expectedParams?.filter(ep => (typeof params[ep]) === 'undefined');

    if(missingParams.length) {
        console.warn(`param(s) missing for component ${component.name} route =>`, { expectedParams, missingParams, route });
        return;
    }

    return expectedParams.reduce((acc, curr)  => acc?.replace(`:${curr}`, params[curr]), route);
}