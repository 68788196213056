<div>
  <ng-template #noMessages>
    <div class="mat-body-2 pb-2">No messages have been sent.</div>
  </ng-template>
  <ng-container *ngIf="messagesSvc.messagesPresent$ | async; else noMessages">
    <div class="mb-1 flex flex-col gap-1">
      <div
        *ngFor="let message of messagesSvc.messages$ | async"
        class="message flex flex-col p-1 rounded shrink"
        [ngClass]="{
          'bg-light-gray': message.createdBy.email === 'You',
          'items-end': message.createdBy.email === 'You',
          'self-end': message.createdBy.email === 'You',
          dark: message.createdBy.email !== 'You',
          'self-start': message.createdBy.email !== 'You'
        }"
      >
        <div class="pb-1 opacity-50 flex flex-row gap-1">
          <div class="mat-body-2">{{ message.createdBy?.email }}</div>
          <div class="mat-caption">
            {{ message.createdAt | date: 'MM/dd/YYYY hh:mm
            aa' }}
          </div>
        </div>
        <div>
          {{ message.text }}
        </div>
        <div *ngIf="message.attachments?.length" class="mt-1">
          <evo-entity-attachments
            [attachments]="message.attachments"
          ></evo-entity-attachments>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="messageForm">
    <form [formGroup]="messageForm">
      <mat-form-field appearance="outline">
        <textarea
          autocomplete="off"
          matInput
          placeholder="Message"
          formControlName="text"
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
        ></textarea>
        <button
          (click)="openAttachmentModal()"
          mat-icon-button
          class="primary"
          matSuffix
          [disabled]="busy"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <evo-button
      color="primary"
      class="float-right"
      (click)="postMessage(messageForm?.value)"
      [disabled]="!messageForm.valid"
      [busy]="busy"
      ><mat-icon>send</mat-icon> Send</evo-button
    >
  </ng-container>
  <evo-pending-attachments
    [files]="files"
    [formData]="formData"
    (remove)="onRemove($event)"
  ></evo-pending-attachments>
</div>
