import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Config, EvoCookieService, TIconicRoute } from '@evo/ui/common';
import { IOrganization, IUser } from '@evo/iso/common';
import { AppEnum } from '@evo/iso/common';

@Component({
  selector: 'evo-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent {
  @Input() user: Partial<IUser>;
  @Input() org?: Partial<IOrganization>;
  @Input() routes: TIconicRoute[];
  @Input() app: AppEnum;
  @Output() logoutEvent = new EventEmitter();
  silver = true;

  constructor(private cookieService: EvoCookieService, private env: Config) {}

  logout() {
    this.logoutEvent.emit();
  }
}
