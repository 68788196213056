import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { DefaultApi } from '@evo/generated/account';
import { formatRegexes } from '@evo/ui/angular-serializable-forms';
import { BaseComponent } from '../../base-classes/base.component';
import { SnackService } from '../../services/snack.service';
import { EvoCookieService } from '../../services/evo-cookie.service';
import { decode } from '../../functions/decode.function';

@Component({
  selector: 'evo-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent extends BaseComponent implements OnInit {
  @Input() api: DefaultApi;
  @Output() authenticated = new EventEmitter();
  formGroup!: FormGroup;
  userId$$ = new BehaviorSubject('');
  userId$ = this.userId$$.asObservable().pipe(
    filter((userId) => !!userId),
    tap(() => {
      this.showLogin = false;
      this.showMfa = false;
    }),
    tap((userId) => this.authenticated.emit(userId))
  );
  name: string;
  showLogin = true;
  showMfa = false;
  formGroupMfa: FormGroup<{ mfa: FormControl<null> }>;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackService,
    private cookieService: EvoCookieService
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(formatRegexes.email),
        ],
      }),
      password: [null, Validators.required],
    });

    this.formGroupMfa = this.formBuilder.group({
      mfa: [null, Validators.required],
    });
  }

  async login(creds: { name: string; password: string }) {
    this.busy$$.next(true);

    try {
      this.name = creds.name;
      await this.api.authControllerAuthenticate(creds);

      this.busy$$.next(false);
      this.showMfa = true;
      this.showLogin = false;
    } catch (error: any) {
      this.handleError(error);
      this.showMfa = false;
      this.showLogin = true;
    }
  }

  async validateMfa(mfa: string) {
    const username = this.name;
    this.busy$$.next(true);
    try {
      const { token, idToken } = (
        await this.api.authControllerValidateMfaCode({ mfa, username })
      ).data;
      if (token) {
        this.cookieService.setSessionTokens(token, idToken);
        this.cookieService.deleteEmail();
        const userId = decode(token).sub;
        this.userId$$.next(userId);
        this.busy$$.next(false);
      }
    } catch (error: any) {
      this.handleError(error);
    }
  }

  handleError(e: Error) {
    console.warn(e);
    this.busy$$.next(false);
    this.snackBar.oops();
  }
}
