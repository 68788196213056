import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SnackService } from '@evo/ui/common';

@Component({
  selector: 'evo-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
})
export class CopyComponent {
  @Input() value: string;
  @Input() tooltip: string;
  @ViewChild('target') target: ElementRef<HTMLInputElement>;

  constructor(private snackSvc: SnackService) {}

  copyUrl() {
    let successful: boolean;

    try {
      const copyTextarea = this.target.nativeElement;
      console.warn(copyTextarea.value);
      copyTextarea.focus();
      copyTextarea.select();

      successful = document.execCommand('copy');
    } catch (err) {
      successful = false;
    }

    if (successful) {
      this.snackSvc.open(`Copied "${this.value}" to clipboard!`);
    } else {
      this.snackSvc.open('Sorry, unable to copy. Please copy manually.');
    }
  }
}
