<ng-container *ngIf="(userId$ | async) else auth">
  You are currently signed in.
</ng-container>

<ng-template #auth>
  <ng-container *ngIf="showLogin">
    <form class="mt-2" [formGroup]="formGroup">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>email</mat-icon>
        <input matInput type="text" formControlName="name" placeholder="Email" />
      </mat-form-field>
      <evo-error name="name" [form]="formGroup"></evo-error>

      <mat-form-field appearance="outline">
        <mat-icon matPrefix>password</mat-icon>
        <input matInput type="password" formControlName="password" placeholder="Password" />
      </mat-form-field>
      <evo-error name="password" [form]="formGroup"></evo-error>

      <button color="primary" class="xl" (click)="login(formGroup.value)"
        [disabled]="(busy$ | async) || formGroup.invalid || formGroup.pristine" mat-raised-button
        *ngIf="!(busy$ | async)">
        Authenticate
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="showMfa">
    <form class="mt-2" [formGroup]="formGroupMfa">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>pin</mat-icon>
        <input matInput type="text" formControlName="mfa" placeholder="code" />
      </mat-form-field>

      <button
        color="primary"
        class="xl"
        *ngIf="!(busy$ | async)"
        (click)="validateMfa(formGroupMfa.value.mfa)"
        [disabled]="
          (busy$ | async) || formGroupMfa.invalid || formGroupMfa.pristine
        "
        mat-raised-button
      >
        Validate
      </button>
      <div *ngIf="busy$ | async" class="row">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
    </form>
  </ng-container>
</ng-template>