import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { formatRegexes } from '@evo/ui/angular-serializable-forms';

@Component({
  selector: 'evo-invite',
  templateUrl: './invite.component.html',
})
export class InviteComponent implements OnInit {
  @Output() invited = new EventEmitter();
  formGroup!: FormGroup;
  busy$$ = new BehaviorSubject(false);
  busy$ = this.busy$$.asObservable();
  emailSent = false;
  email: string;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      email: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(formatRegexes.email),
        ],
      }),
    });
  }

  async invite(creds: { email: string }) {
    this.invited.emit(creds);
    this.emailSent = true;
    this.email = creds.email;
  }
}
