import { Config } from "@evo/ui/common";

export const environment: Config = {
  production: true,
  api: '',
  domain: '.evo-legal.com',
  uiUrlAccount: 'https://account-staging.evo-legal.com',
  uiUrlAdmin: 'https://admin-staging.evo-legal.com',
  uiUrlCompliance: 'https://compliance.evo-legal.com',
  uiUrlReports: 'https://reports-staging.evo-legal.com',
};
