<div class="flex flex-row gap-1 items-center">
  <mat-form-field class="m-0" appearance="outline" class="width-full">
    <input matInput type="text" [value]="value" #target readonly />
  </mat-form-field>
  <button
    [matTooltip]="tooltip"
    color="primary"
    mat-mini-fab
    (click)="copyUrl()"
  >
    <mat-icon>content_copy</mat-icon>
  </button>
</div>
