import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

const datePipe = new DatePipe('en-US');

@Pipe({ name: 'monthDayYear' })
export class MonthDayYearPipe implements PipeTransform {
  transform(key: string | number | Date) {
    return datePipe.transform(key, 'MM/dd/YYYY');
  }
}
