import { IReportFacility } from './report-facility.interface';

export interface IOrganization {
  id: string;
  name: string;
  logoUrl?: string;
  codeOfConductUrl?: string;
  phoneNumber?: string;
  facilities: IReportFacility[];
}
