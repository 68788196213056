export interface IClaims {
  apps: string | undefined;
  facs: string | undefined;
  hasReportsOnly: string;
  isAdmin: 'true' | 'false';
  isAnon: 'true' | 'false';
  isCompAssignee: 'true' | 'false';
  isCompOfficer: 'true' | 'false';
  isCompReporter: 'true' | 'false';
  isQuota: 'true' | 'false';
  isSysAdmin: 'true' | 'false';
  orgId: string | undefined;
  reports: string | undefined;
}
