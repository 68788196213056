import { ILineItemPriceInfo } from '../interfaces/line-item-price-info.interface';
import { calculateDiscount } from './calculate-discount.function';
import { calculatePriceInCents } from './calculate-price.function';

export function calculateCompliancePrice(
  count: number,
  apps: string[],
  ratio = 1
) {
  const basePrice = calcBasePrice(count);

  const lineItemPriceInfo: ILineItemPriceInfo = {
    price: calculatePriceInCents(basePrice, ratio),
    discount: calculateDiscount(ratio),
    apps,
  } as ILineItemPriceInfo;

  return lineItemPriceInfo;
}

function calcBasePrice(count: number) {
  if (count === 1) {
    return 1_249.0;
  }
  if (count === 2) {
    return 1_489.0;
  }
  if (count === 3) {
    return 1_657.0;
  }
  if (count === 4) {
    return 1_873.0;
  }
  if (count === 5) {
    return 2_089.0;
  }
  if (count === 6) {
    return 2_233.0;
  }
  if (count === 7) {
    return 2_437.0;
  }
  if (count === 8) {
    return 2_641.0;
  }
  if (count === 9) {
    return 2_845.0;
  }
  if (count === 10) {
    return 2_929.0;
  }
  if (count === 11) {
    return 3_121.0;
  }
  if (count === 12) {
    return 3_313.0;
  }
  if (count === 13) {
    return 3_505.0;
  }
  if (count === 14) {
    return 3_697.0;
  }
  if (count === 15) {
    return 3_889.0;
  }
  if (count === 16) {
    return 4_081.0;
  }
  if (count === 17) {
    return 4_273.0;
  }
  if (count === 18) {
    return 4_465.0;
  }
  if (count === 19) {
    return 4_657.0;
  }
  if (count === 20) {
    return 4_609.0;
  }
  if (count === 21) {
    return 4_789.0;
  }
  if (count === 22) {
    return 4_969.0;
  }
  if (count === 23) {
    return 5_149.0;
  }
  if (count === 24) {
    return 5_329.0;
  }
  if (count === 25) {
    return 5_509.0;
  }
  if (count === 26) {
    return 5_689.0;
  }
  if (count === 27) {
    return 5_869.0;
  }
  if (count === 28) {
    return 6_049.0;
  }
  if (count === 29) {
    return 5_881.0;
  }
  if (count === 30) {
    return 6_049.0;
  }
  if (count === 31) {
    return 6_217.0;
  }
  if (count === 32) {
    return 6_385.0;
  }
  if (count === 33) {
    return 6_553.0;
  }
  if (count === 34) {
    return 6_721.0;
  }
  if (count === 35) {
    return 6_889.0;
  }
  if (count === 36) {
    return 7_057.0;
  }
  if (count === 37) {
    return 7_225.0;
  }
  if (count === 38) {
    return 7_393.0;
  }
  if (count === 39) {
    return 7_093.0;
  }
  if (count === 40) {
    return 7_249.0;
  }
  if (count === 41) {
    return 7_405.0;
  }
  if (count === 42) {
    return 7_561.0;
  }
  if (count === 43) {
    return 7_717.0;
  }
  if (count === 44) {
    return 7_873.0;
  }
  if (count === 45) {
    return 8_029.0;
  }
  if (count === 46) {
    return 8_185.0;
  }
  if (count === 47) {
    return 8_341.0;
  }
  if (count === 48) {
    return 8_497.0;
  }
  if (count === 49) {
    return 8_653.0;
  }
  if (count === 50) {
    return 8_809.0;
  }
  if (count === 51) {
    return 8_965.0;
  }
  if (count === 52) {
    return 9_121.0;
  }
  if (count === 53) {
    return 9_277.0;
  }
  if (count === 54) {
    return 9_433.0;
  }
  if (count === 55) {
    return 9_589.0;
  }
  if (count === 56) {
    return 9_745.0;
  }
  if (count === 57) {
    return 9_901.0;
  }
  if (count === 58) {
    return 10_057.0;
  }
  if (count === 59) {
    return 10_213.0;
  }
  if (count === 60) {
    return 10_369.0;
  }
  if (count === 61) {
    return 10_525.0;
  }
  if (count === 62) {
    return 10_681.0;
  }
  if (count === 63) {
    return 10_837.0;
  }
  if (count === 64) {
    return 10_993.0;
  }
  if (count === 65) {
    return 11_149.0;
  }
  if (count === 66) {
    return 11_305.0;
  }
  if (count === 67) {
    return 11_461.0;
  }
  if (count === 68) {
    return 11_617.0;
  }
  if (count === 69) {
    return 11_773.0;
  }
  if (count === 70) {
    return 11_929.0;
  }
  if (count === 71) {
    return 12_085.0;
  }
  if (count === 72) {
    return 12_241.0;
  }
  if (count === 73) {
    return 12_397.0;
  }
  if (count === 74) {
    return 12_553.0;
  }
  if (count === 75) {
    return 12_709.0;
  }
  if (count === 76) {
    return 12_865.0;
  }
  if (count === 77) {
    return 13_021.0;
  }
  if (count === 78) {
    return 13_177.0;
  }
  if (count === 79) {
    return 13_333.0;
  }
  if (count === 80) {
    return 13_489.0;
  }
  if (count === 81) {
    return 13_645.0;
  }
  if (count === 82) {
    return 13_801.0;
  }
  if (count === 83) {
    return 13_957.0;
  }
  if (count === 84) {
    return 14_113.0;
  }
  if (count === 85) {
    return 14_269.0;
  }
  if (count === 86) {
    return 14_425.0;
  }
  if (count === 87) {
    return 14_581.0;
  }
  if (count === 88) {
    return 14_737.0;
  }
  if (count === 89) {
    return 14_893.0;
  }
  if (count === 90) {
    return 15_049.0;
  }
  if (count === 91) {
    return 15_205.0;
  }
  if (count === 92) {
    return 15_361.0;
  }
  if (count === 93) {
    return 15_517.0;
  }
  if (count === 94) {
    return 15_673.0;
  }
  if (count === 95) {
    return 15_829.0;
  }
  if (count === 96) {
    return 15_985.0;
  }
  if (count === 97) {
    return 16_141.0;
  }
  if (count === 98) {
    return 16_297.0;
  }
  if (count === 99) {
    return 16_453.0;
  }
  if (count >= 100) {
    return 16_609.0;
  }

  return 16_609.0;
}
