import { Component, Input } from '@angular/core';
import { GlobalAttachmentsService, SnackService } from '@evo/ui/common';

@Component({
  selector: 'evo-global-download-button',
  templateUrl: './global-download-button.component.html',
})
export class GlobalDownloadButtonComponent {
  @Input() id: string;

  constructor(
    private snackBar: SnackService,
    private globalAttachmentsSvc: GlobalAttachmentsService
  ) {}

  async download() {
    try {
      const { url } = (
        await this.globalAttachmentsSvc.downloadAttachment(this.id)
      ).data;
      if (url) {
        window.location.href = url;
      } else {
        this.snackBar.oops();
      }
    } catch (error: any) {
      this.snackBar.oops();
    }
  }
}
