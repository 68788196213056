import { AppEnum } from '../enums/app.enum';

export const oneAppConfig: Record<
  string,
  { url: string; label: string; svg: string; marketing?: string; app: AppEnum }
> = {
  COV: {
    url: 'https://evolve-legal.webflow.io/covid-19-home',
    label: 'COVID-19',
    svg: 'covid19.svg',
    app: AppEnum.COV,
  },
  DOC: {
    url: 'https://documents.evolve-legal.com',
    label: 'DOCUMENT STORE',
    svg: 'contracts.svg',
    app: AppEnum.DOC,
  },
  ADMFED: {
    url: 'https://admissions-federal.evolve-legal.com/dashboard',
    label: 'ADMISSIONS: FEDERAL',
    svg: 'admissions.svg',
    app: AppEnum.ADMFED,
    marketing: 'https://www.evolve-legal.com/admissions',
  },
  ADMMIC: {
    url: 'https://admissions-michigan.evolve-legal.com/dashboard',
    label: 'ADMISSIONS: MICHIGAN',
    svg: 'admissions.svg',
    app: AppEnum.ADMMIC,
    marketing: 'https://www.evolve-legal.com/admissions',
  },
  ADM: {
    url: 'https://admissions-ohio.evolve-legal.com/dashboard',
    label: 'ADMISSIONS: OHIO',
    svg: 'admissions.svg',
    app: AppEnum.ADM,
    marketing: 'https://www.evolve-legal.com/admissions',
  },
  COM: {
    url: 'https://compliance.evolve-legal.com/dashboard',
    label: 'COMPLIANCE',
    svg: 'compliance.svg',
    app: AppEnum.COM,
    marketing: 'https://www.evolve-legal.com/compliance',
  },
  REPORTS: {
    url: 'https://reports.evolve-legal.com',
    label: 'REPORTS',
    svg: 'icon_report.svg',
    app: AppEnum.REPORTS,
    marketing: 'https://www.evolve-legal.com/compliance',
  },
  VMA: {
    url: 'https://vma.evolve-legal.com',
    label: 'VIRTUAL MEDICAID ASSISTANT',
    svg: 'evolve-ball.svg',
    app: AppEnum.VMA,
    marketing: 'https://vma.evolve-legal.com',
  },
  LAWBOT: {
    url: 'https://ai.evolve-legal.com',
    label: 'AI',
    svg: 'evolve-ball.svg',
    app: AppEnum.LAWBOT,
    marketing: 'https://ai.evolve-legal.com',
  },
  DOX: {
    url: 'https://dox.evolve-legal.com',
    label: 'DOX',
    svg: 'evolve-ball.svg',
    app: AppEnum.DOX,
    marketing: 'https://dox.evolve-legal.com',
  },
};
