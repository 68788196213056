import {
  GlobalNotificationTypeEnum,
  IGlobalNotification,
} from '@evo/iso/common';

export function getNotificationRoute({
  notificationType,
  entityId,
}: IGlobalNotification): string {
  switch (notificationType) {
    case GlobalNotificationTypeEnum.COMPLIANCE_REPORT_CREATED:
      return `reports/${entityId}`;
    case GlobalNotificationTypeEnum.COMPLIANCE_REPORT_MESSAGE_SENT:
      return `reports/${entityId}?tab=messages`;
    default:
      return '';
  }
}
