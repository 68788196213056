<div class="flex flex-row flex-wrap wrapper">
  <div class="field grow" *ngIf="form && uiOptions" [formGroup]="form">
    <div [ngSwitch]="uiOptions | typeFor: field">
      <ng-container *ngSwitchCase="'date'">
        <mat-form-field appearance="outline">
          <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
          <input
            autocomplete="off"
            [placeholder]="uiOptions | labelFor: field"
            matInput
            [matDatepicker]="date"
            [formControlName]="field"
            [readonly]="readOnly"
          />
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
          <mat-error
            *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
            >{{ form?.get(field)?.errors | formatError | uppercase }}</mat-error
          >
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'select'">
        <mat-form-field appearance="outline">
          <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
          <mat-select
            [panelClass]="readOnly ? 'hidden' : ''"
            [readonly]="readOnly"
            [formControlName]="field"
            [placeholder]="uiOptions | labelFor: field"
            [multiple]="uiOptions | attrFor: field:'multiple'"
          >
            <ng-container
              *ngFor="
                let option of uiOptions | optionsFor: field;
                trackBy: trackByOptionValue
              "
            >
              <mat-option
                *ngIf="!readOnly || option.value === form?.get(field)?.value"
                [value]="option.value"
              >
                {{ option.display }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
            >{{ form?.get(field)?.errors | formatError | uppercase }}</mat-error
          >
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'button-toggle'">
        <div class="mb-2">
          <div>
            <label>{{ uiOptions | labelFor: field }}</label>
          </div>
          <mat-button-toggle-group [formControlName]="field">
            <ng-container
              *ngFor="
                let option of uiOptions | optionsFor: field;
                trackBy: trackByOptionValue
              "
            >
              <mat-button-toggle
                *ngIf="!readOnly || option.value === form?.get(field)?.value"
                [value]="option.value"
              >
                {{ option.display }}
              </mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
          <mat-error
            *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
            >{{ form?.get(field)?.errors | formatError | uppercase }}</mat-error
          >
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'slide-toggle'">
        <mat-slide-toggle [formControlName]="field" [readonly]="readOnly"
          ><mat-label>{{
            uiOptions | labelFor: field
          }}</mat-label></mat-slide-toggle
        >
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox'">
        <mat-checkbox
          color="primary"
          [formControlName]="field"
          [disabled]="readOnly"
          ><mat-label>{{
            uiOptions | labelFor: field
          }}</mat-label></mat-checkbox
        >
      </ng-container>

      <ng-container *ngSwitchCase="'textarea'">
        <mat-form-field appearance="outline">
          <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
          <textarea
            autocomplete="off"
            [readonly]="readOnly"
            autocomplete="off"
            [placeholder]="uiOptions | labelFor: field"
            matInput
            [formControlName]="field"
          ></textarea>
          <mat-error
            *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
            >{{ form?.get(field)?.errors | formatError | uppercase }}</mat-error
          >
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <mat-form-field appearance="outline">
          <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
          <input
            autocomplete="off"
            [readonly]="readOnly"
            [placeholder]="uiOptions | labelFor: field"
            matInput
            [formControlName]="field"
            type="{{ uiOptions | typeFor: field }}"
            min="{{ uiOptions | attrFor: field:'min' }}"
            max="{{ uiOptions | attrFor: field:'max' }}"
            step="{{ uiOptions | attrFor: field:'step' }}"
          />
          <mat-error
            *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
            >{{ form?.get(field)?.errors | formatError | uppercase }}</mat-error
          >
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
