import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[dragAndDrop]',
})
export class DragAndDropDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() drop = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault?.();
    evt.stopPropagation?.();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault?.();
    evt.stopPropagation?.();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    if (evt.dataTransfer) {
      evt.preventDefault?.();
      evt.stopPropagation?.();

      this.fileOver = false;

      let files = evt.dataTransfer?.files;

      if (files?.length > 0) {
        this.drop.emit(files);
      }
    }
  }
}
