import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'evo-pending-attachments',
  templateUrl: './pending-attachments.component.html',
})
export class PendingAttachmentsComponent {
  @Input() files: File[];
  @Input() formData: FormData;
  @Output() remove = new EventEmitter();

  onRemove(file: File) {
    // need to abstract this to a function or service
    if (this.formData) {
      const fd = new FormData();
      this.formData.forEach((f) => {
        if ((f as any).name !== file.name) {
          fd.append('files[]', f, (f as any).name);
        }
      });

      this.formData = fd;
      this.files = this.files?.filter((f) => f.name !== file.name);

      this.remove.emit({ files: this.files, formData: this.formData });
    }
  }
}
