import { Injectable } from '@angular/core';
import {
  CreateAttachmentDto,
  DefaultApi,
  GlobalAttachment,
} from '@evo/generated/reports';
import { MultipartContentClientService } from './multipart-content-client.service';

@Injectable()
export class GlobalAttachmentsService {
  constructor(
    private api: DefaultApi,
    private multipartContentClientSvc: MultipartContentClientService
  ) {}

  async loadAttachmentsForEntity(
    entityId: string
  ): Promise<GlobalAttachment[]> {
    const attachments = (
      await this.api.globalAttachmentsControllerGetAttachmentsByEntityId(
        entityId
      )
    ).data;

    return attachments;
  }

  async createAttachment(
    attachment: CreateAttachmentDto,
    formData: FormData
  ): Promise<GlobalAttachment[]> {
    await this.multipartContentClientSvc.postFilesAndData(
      formData,
      'global-attachments',
      attachment
    );

    return await this.loadAttachmentsForEntity(attachment.entityId);
  }

  async downloadAttachment(attachmentId: string) {
    return await this.api.globalDownloadsControllerGet(attachmentId);
  }
}
