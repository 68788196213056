import { Component, Input } from '@angular/core';

@Component({
  selector: 'evo-app-bar',
  templateUrl: './app-bar.component.html'
})
export class AppBarComponent {
  @Input() link!: string;
  @Input() silver: boolean;
}
