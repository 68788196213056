import { Injectable } from '@angular/core';
import { DefaultApi, GlobalMessage } from '@evo/generated/reports';
import { BehaviorSubject, map } from 'rxjs';
import { count } from '../functions/count.function';
import { isNotZero } from '../functions/is-not-zero.function';
import { negate } from '../functions/negate.function';
import { MultipartContentClientService } from './multipart-content-client.service';

@Injectable()
export class GlobalMessagesService {
  private messages$$ = new BehaviorSubject<GlobalMessage[]>([]);
  messages$ = this.messages$$.asObservable();
  messagesCount$ = this.messages$.pipe(map(count));
  messagesPresent$ = this.messagesCount$.pipe(map(isNotZero));
  messagesAbsent$ = this.messagesPresent$.pipe(map(negate));

  constructor(
    private api: DefaultApi,
    private multipartContentClientSvc: MultipartContentClientService
  ) {}

  async loadMessagesForEntity(entityId: string) {
    const messages = (
      await this.api.globalMessagesControllerGetByEntityId(entityId)
    ).data;
    this.messages$$.next(messages);
  }

  async createMessage(message: GlobalMessage) {
    const messages = (await this.api.globalMessagesControllerCreate(message))
      .data;
    this.messages$$.next(messages);
  }

  async createMessageWithAttachments(
    message: GlobalMessage,
    formData?: FormData
  ) {
    if (formData) {
      await this.multipartContentClientSvc.postFilesAndData(
        formData,
        `global-messages/with-attachment/${message.appId}`,
        message
      );
      await this.loadMessagesForEntity(message.entityId);
    } else {
      await this.createMessage(message);
    }
  }
}
