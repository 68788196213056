<mat-toolbar class="mat-elevation-z8">
  <div class="flex flex-row">
    <!-- THIS IS CRAP BUT JUST DOING IT FOR DISPLAY FOR NOW -->
    <img *ngIf="org?.logoUrl" [src]="org?.logoUrl" class="ht-64" />
    <img
      *ngIf="!org?.logoUrl && !silver"
      src="assets/evolve-logo.svg"
      class="logo shadow-8"
    />
    <img
      *ngIf="!org?.logoUrl && silver"
      src="assets/silverbridge-logo.jpeg"
      class="logo ht-64"
    />
    <h1 class="ml-2 ht-64">{{ org?.name }}</h1>
  </div>
  <div class="button-group row flex-end">
    <evo-notification-indicator [app]="app"></evo-notification-indicator>
    <button color="primary" mat-mini-fab [matMenuTriggerFor]="menu">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <evo-busy [busy]="!user"></evo-busy>
      <ng-container *ngIf="user">
        <div class="p-2" mat-list-item>
          <div class="mat-body-2">{{ user.email }}</div>
          <div>{{ user.phone }}</div>
        </div>
        <mat-divider></mat-divider>
        <ng-container *ngIf="routes?.length">
          <evo-router-links [routes]="routes"></evo-router-links>
          <mat-divider></mat-divider>
        </ng-container>
        <button mat-menu-item (click)="logout()">
          <mat-icon color="accent">logout</mat-icon> Sign out
        </button>
      </ng-container>
    </mat-menu>
  </div>
</mat-toolbar>
