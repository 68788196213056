import { EventEmitter, Injectable, Output } from "@angular/core";

export enum FlyoutType {
    TASK = 'task',
    NOTIFICATION = 'notification'
}

@Injectable({
    providedIn: 'root'
})
export class FlyoutService {
    @Output() openFlyout = new EventEmitter();
    type: FlyoutType;

    getType() {
        return this.type;
    }

    setTypeAndOpen(data: any, type: FlyoutType) {
        this.openFlyout.emit({data, type});
    }
}