<div>
  <ng-template #noNotes>
    <div class="mat-body-2 pb-2">No notes have been added.</div>
  </ng-template>
  <ng-container *ngIf="notesSvc.notesPresent$ | async; else noNotes">
    <div>
      <div class="flex flex-col">
        <div class="pb-2" *ngFor="let note of notesSvc.notes$ | async">
          <div>
            <div
              class="flex flex-col border-solid border-l-4 border-y-0 border-r-0 pl-1"
            >
              <div class="pb-1 opacity-50">
                <span class="mat-body-2 pr-1">{{ note.createdBy?.email }}</span>
                <span class="mat-caption">{{
                  note.createdAt | date: 'MM/dd/YYYY hh:mm
                  aa'
                }}</span>
              </div>
              <div>{{ note.text }}</div>
              <div *ngIf="note.attachments?.length" class="mt-1">
                <evo-entity-attachments
                  [attachments]="note.attachments"
                ></evo-entity-attachments>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="noteForm">
    <form [formGroup]="noteForm">
      <mat-form-field appearance="outline">
        <textarea
          autocomplete="off"
          matInput
          placeholder="Note"
          formControlName="text"
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
        ></textarea>

        <button
          (click)="openAttachmentModal()"
          mat-icon-button
          class="primary"
          matSuffix
          [disabled]="busy"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <evo-button
      color="primary"
      class="float-right"
      (click)="postNote(noteForm?.value)"
      [disabled]="!noteForm.valid"
      [busy]="busy"
      ><mat-icon>save</mat-icon> Save</evo-button
    >
  </ng-container>
  <evo-pending-attachments
    [files]="files"
    [formData]="formData"
    (remove)="onRemove($event)"
  ></evo-pending-attachments>
</div>
