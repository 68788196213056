<form [formGroup]="form" class="column" *ngIf="uiOptions">
  <div
    class="flex flex-row flex-wrap gap-1"
    *ngFor="let row of uiOptions?.fieldLayout"
  >
    <div class="field grow" *ngFor="let field of row">
      <div [ngSwitch]="uiOptions | typeFor: field">
        <ng-container *ngSwitchCase="'date'">
          <mat-form-field appearance="outline">
            <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
            <input
              [placeholder]="uiOptions | labelFor: field"
              matInput
              [matDatepicker]="date"
              [formControlName]="field"
              [readonly]="readOnly || (uiOptions | attrFor: field:'readOnly')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="date"
            ></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
            <mat-error
              *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
              >{{
                form?.get(field)?.errors | formatError | uppercase
              }}</mat-error
            >
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
          <mat-form-field appearance="outline">
            <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
            <mat-select
              [formControlName]="field"
              [placeholder]="uiOptions | labelFor: field"
              [multiple]="uiOptions | attrFor: field:'multiple'"
            >
              <mat-option
                *ngFor="
                  let option of uiOptions | optionsFor: field;
                  trackBy: trackByOptionValue
                "
                [value]="option.value"
              >
                {{ option.display }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
              >{{
                form?.get(field)?.errors | formatError | uppercase
              }}</mat-error
            >
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'button-toggle'">
          <mat-button-toggle-group [formControlName]="field">
            <mat-button-toggle
              *ngFor="
                let option of uiOptions | optionsFor: field;
                trackBy: trackByOptionValue
              "
              [value]="option.value"
            >
              {{ option.display }}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error
            *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
            >{{ form?.get(field)?.errors | formatError | uppercase }}</mat-error
          >
        </ng-container>

        <ng-container *ngSwitchCase="'slide-toggle'">
          <mat-slide-toggle
            [formControlName]="field"
            [readonly]="readOnly || (uiOptions | attrFor: field:'readOnly')"
            ><mat-label>{{
              uiOptions | labelFor: field
            }}</mat-label></mat-slide-toggle
          >
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
          <mat-checkbox
            color="primary"
            [formControlName]="field"
            [readonly]="readOnly || (uiOptions | attrFor: field:'readOnly')"
            ><mat-label>{{
              uiOptions | labelFor: field
            }}</mat-label></mat-checkbox
          >
        </ng-container>

        <ng-container *ngSwitchCase="'textarea'">
          <mat-form-field appearance="outline">
            <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
            <textarea
              [readonly]="readOnly || (uiOptions | attrFor: field:'readOnly')"
              autocomplete="off"
              [placeholder]="uiOptions | labelFor: field"
              matInput
              [formControlName]="field"
              (click)="touch(field)"
            ></textarea>
            <mat-error
              *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
              >{{
                form?.get(field)?.errors | formatError | uppercase
              }}</mat-error
            >
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <mat-form-field appearance="outline">
            <mat-label>{{ uiOptions | labelFor: field }}</mat-label>
            <input
              autocomplete="off"
              [readonly]="readOnly || (uiOptions | attrFor: field:'readOnly')"
              [placeholder]="uiOptions | labelFor: field"
              matInput
              [formControlName]="field"
              type="{{ uiOptions | typeFor: field }}"
              min="{{ uiOptions | attrFor: field:'min' }}"
              max="{{ uiOptions | attrFor: field:'max' }}"
              step="{{ uiOptions | attrFor: field:'step' }}"
              (click)="touch(field)"
            />
            <mat-error
              *ngIf="form?.get(field)?.touched || form?.get(field)?.dirty"
              >{{
                form?.get(field)?.errors | formatError | uppercase
              }}</mat-error
            >
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </div>
</form>
