import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppEnum } from '@evo/iso/common';
import { GlobalNotificationsService } from '../../services/global-notifications.service';
import { NotificationsComponent } from '../notifications/notifications.component';

@Component({
  selector: 'evo-notification-indicator',
  templateUrl: './notification-indicator.component.html',
})
export class NotificationsIndicatorComponent implements OnInit {
  @Input() app: AppEnum;
  @Input() height = '90%';
  @Input() width = '90%';

  constructor(
    private dialog: MatDialog,
    public notificationSvc: GlobalNotificationsService
  ) {}

  ngOnInit() {
    if (!this.app) {
      console.warn(
        `${NotificationsComponent.name} is missing @Input() app: AppEnum;`
      );
    } else {
      this.notificationSvc.getAppNotifications(this.app);
    }
  }

  openNotificationsDialog() {
    this.dialog.open(NotificationsComponent, {
      height: this.height,
      width: this.width,
    });
  }
}
