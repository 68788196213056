import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IIdentityToken } from '@evo/iso/common';
import { decode } from '../functions/decode.function';
import { EvoCookieService } from '../services/evo-cookie.service';

@Pipe({ name: 'idToken' })
export class IdTokenPipe implements PipeTransform {
  transform(_val: any) {
    const idToken = this.cookieService.getIdToken();

    if (!idToken) {
      return {} as any;
    }
    const decoded = decode(idToken) as IIdentityToken;

    return decoded;
  }

  constructor(
    private datePipe: DatePipe,
    private cookieService: EvoCookieService
  ) {}
}
