import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { EvoCookieService } from '../services/evo-cookie.service';
import { RedirectService } from '../services/redirect.service';
import { Config } from '../providers/config.class';
import { ClaimService } from '../services/claim.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private cookieService: EvoCookieService,
    private redirectService: RedirectService,
    private config: Config,
    private claimSvc: ClaimService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (this.cookieService.getAuthToken()) {
      if (this.claimSvc.get().hasReportsOnly) {
        const reportsHost = this.config.uiUrlReports.split('//')[1];
        if (window.location.host !== reportsHost) {
          window.location.href = this.config.uiUrlReports;
        }
      }

      return true;
    } else {
      window.location.href = this.redirectService.getLoginRedirectUrl();
      return false;
    }
  }
}
