import { Component, Input } from '@angular/core';
import { GlobalNotification } from '@evo/generated/reports';
import { GlobalNotificationsService } from '../../services/global-notifications.service';

@Component({
  selector: 'evo-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent {
  notifications!: Partial<GlobalNotification>[];
  now = new Date();
  constructor(public notificationSvc: GlobalNotificationsService) {}
}
