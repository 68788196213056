import { Component, Input } from '@angular/core';
import { GlobalNotification } from '@evo/generated/reports';
import { GlobalNotificationsService } from '../../services/global-notifications.service';

@Component({
  selector: 'evo-notification-list',
  templateUrl: './notification-list.component.html',
})
export class NotificationListComponent {
  @Input() notifications!: Partial<GlobalNotification>[];
  @Input() enableDeleting = false;
  @Input() emptyMessage = `You're all caught up!`;

  constructor(public notificationSvc: GlobalNotificationsService) {}
}
