export enum AppEnum {
  ACC = 'ACC',
  ADM = 'ADM',
  ADMMIC = 'ADMMIC',
  ADMFED = 'ADMFED',
  COM = 'COM',
  COV = 'COV',
  DOC = 'DOC',
  DOX = 'DOX',
  DQA = 'DQA',
  EVO = 'EVO',
  GLOBAL = 'GLOBAL',
  LAWBOT = 'LAWBOT',
  PPL = 'PPL',
  REPORTS = 'REPORTS',
  REV = 'REV',
  VMA = 'VMA',
}
