<mat-toolbar class="lite row space-between center mat-elevation-z8">
  <div class="row header">
    <div>
      <ng-container *ngIf="link">
        <a [href]="link">
          <evo-logo [silver]="silver"></evo-logo>
        </a>
      </ng-container>
      <ng-container *ngIf="!link">
          <evo-logo></evo-logo>
      </ng-container>
    </div>
  </div>
  <div class="row center flex-end">
    <ng-content></ng-content>
  </div>
</mat-toolbar>