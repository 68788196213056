import { DefaultApi } from '@evo/generated/account';
import { GlobalAttachmentsService } from '../services/global-attachments.service';
import { MultipartContentClientService } from '../services/multipart-content-client.service';

// Why all this jazz?
// The service lives in a shared-lib and uses a dep that is non-injectable in that context due to namespace collisions (aka multiple classes w/ same name)
// so that requires a useFactory expression.
// Since we are using a shared behavior subject in the service, the service must be a singleton;
// nonetheless, we still may be able to use providedIn: 'root' and eliminate the manual singleton-management.
// For now... this is fine.

export function globalAttachmentsServiceProvider(
  api: any,
  cachedInstance: GlobalAttachmentsService | undefined,
  cb: (createdInstance: GlobalAttachmentsService) => void
) {
  return {
    provide: GlobalAttachmentsService,
    deps: [api, MultipartContentClientService],
    useFactory: (
      api: DefaultApi,
      multipartContentClientSvc: MultipartContentClientService
    ) => {
      if (cachedInstance) {
        cb(cachedInstance);
        return cachedInstance;
      }

      const createdInstance = new GlobalAttachmentsService(
        api as any,
        multipartContentClientSvc
      );
      cb(createdInstance);
      return createdInstance;
    },
  };
}
