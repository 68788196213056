import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GlobalMessage, User } from '@evo/generated/reports';
import { GlobalMessagesService } from '../../services/global-messages.service';
import { AppEnum } from '@evo/iso/common';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, take, tap } from 'rxjs';

@Component({
  selector: 'evo-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  @Input() messagesSvc: GlobalMessagesService;
  @Input() entityId: string;
  @Input() appId: AppEnum;
  @Input() createdById: string;
  @Input() emitPost = false;
  @Output() post = new EventEmitter();
  messageForm: FormGroup;
  formData: FormData | undefined;
  files: File[] | undefined;
  @Input() busy = true;

  constructor(private fb: FormBuilder, private dialog: MatDialog) {}

  async ngOnInit() {
    this.messageForm = this.fb.group({
      text: new FormControl(null, { validators: [Validators.required] }),
    });

    await this.messagesSvc.loadMessagesForEntity(this.entityId);
    this.busy = false;
  }

  openAttachmentModal() {
    this.dialog
      .open(FileUploadModalComponent, {})
      .afterClosed()
      .pipe(
        filter((output) => !!output?.files?.length),
        take(1),
        tap(({ formData, files }) => {
          this.formData = formData;
          this.files = files;
        })
      )
      .subscribe();
  }

  async postMessage({ text }: GlobalMessage) {
    const message: GlobalMessage = {
      id: undefined as any,
      entityId: this.entityId,
      text,
      createdById: this.createdById,
      createdBy: { id: this.createdById } as User,
      appId: this.appId,
    };

    if (this.emitPost) {
      this.post.emit({ message, formData: this.formData });
    } else {
      this.busy = true;
      await this.messagesSvc.createMessageWithAttachments(
        message,
        this.formData
      );
      this.busy = false;
    }

    this.formData = undefined;
    this.files = undefined;
    this.messageForm.reset();
  }

  onRemove({ files, formData }: { files: File[]; formData: FormData }) {
    this.formData = formData;
    this.files = files;
  }
}
