<div class="mt-2 auto-x">
  <p *ngIf="!notifications?.length" class="p-2 mat-body-2 text-center">
    {{ emptyMessage }}
  </p>
  <button
    mat-stroked-button
    *ngFor="let element of notifications"
    class="p-1 my-1 width-full"
    (click)="notificationSvc.navigateTo(element)"
    [hidden]="element.read && hideRead"
  >
    <div
      class="flex-1 flex flex-row flex-wrap gap-1 justify-between"
      [ngClass]="{ 'opacity-33': element.read }"
    >
      <div class="whitespace-pre-wrap">{{ element.description }}</div>
      <div class="flex flex-row gap-1 items-center grow justify-end">
        {{ element.createdAt | date: 'M/d/YY' }}
        <mat-icon
          matTooltip="Mark as Read"
          class="cursor-pointer"
          *ngIf="!element.dismissed && !element.read"
          (click)="
            notificationSvc.markNotificationRead(element);
            $event.stopPropagation();
            $event.preventDefault()
          "
          >drafts</mat-icon
        >
        <mat-icon
          matTooltip="Mark as Unread"
          class="cursor-pointer"
          *ngIf="!element.dismissed && element.read"
          (click)="
            notificationSvc.markNotificationUnread(element);
            $event.stopPropagation();
            $event.preventDefault()
          "
          >mark_as_unread</mat-icon
        >
        <mat-icon
          matTooltip="Delete"
          class="cursor-pointer"
          *ngIf="!element.dismissed"
          (click)="
            notificationSvc.dismissNotification(element);
            $event.stopPropagation();
            $event.preventDefault()
          "
          >delete</mat-icon
        >
        <mat-icon
          matTooltip="Restore"
          class="cursor-pointer"
          *ngIf="element.dismissed"
          (click)="
            notificationSvc.restoreNotification(element);
            $event.stopPropagation();
            $event.preventDefault()
          "
          >restore_from_trash</mat-icon
        >
      </div>
    </div>
  </button>
</div>
