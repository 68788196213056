import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { MaterialModule } from './material.module';

import { AppBarComponent } from './components/app-bar/app-bar.component';
import { BusyComponent } from './components/busy/busy.component';
import { JsonDetailComponent } from './components/json-detail/json-detail.component';
import { TableComponent } from './components/table/table.component';
import { DynamicTabsComponent } from './components/dynamic-tabs/dynamic-tabs.component';
import { LogoComponent } from './components/logo/logo.component';
import { ModalPageComponent } from './components/modal-page/modal-page.component';
import { NavComponent } from './components/nav/nav.component';
import { PageComponent } from './components/page/page.component';

import { AuthHttpInterceptor } from './interceptors/auth-http.interceptor';

import { OptionalDatePipe } from './pipes/optional-date.pipe';
import { TitlePipe } from './pipes/title.pipe';

import { Config } from './providers/config.class';
import { SerializableFormsModule } from '@evo/ui/angular-serializable-forms';
import { DialogComponent } from './components/dialog/dialog.component';
import { VersionComponent } from './components/version/version.component';
import { AccentButtonComponent } from './components/accent-button/accent-button.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { AccentLinkComponent } from './components/accent-link/accent-link.component';
import { PrimaryLinkComponent } from './components/primary-link/primary-link.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ButtonComponent } from './components/button/button.component';
import { LinkComponent } from './components/link/link.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { AuthComponent } from './components/auth/auth.component';
import { RegisterComponent } from './components/register/register.component';
import { S3KeyPipe } from './pipes/s3-key.pipe';
import { S3NamePipe } from './pipes/s3-name.pipe';
import { RouterLinksComponent } from './components/router-links/router-links.component';
import { InviteComponent } from './components/invite/invite.component';
import { InvitationListComponent } from './components/invitation-list/invitation-list.component';
import { InputFilterForm } from './components/input-filter-form/input-filter-form.component';
import { IdTokenPipe } from './pipes/id-token.pipe';
import { IsCompOfficerDirective } from './directives/is-comp-officer.directive';
import { IsAdminDirective } from './directives/is-admin.directive';
import { IsCompAssigneeDirective } from './directives/is-comp-assignee.directive';
import { IsCompReporterDirective } from './directives/is-comp-reporter.directive';
import { IsSysAdminDirective } from './directives/is-sys-admin.directive';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsIndicatorComponent } from './components/notification-indicator/notification-indicator.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { RowComponent } from './components/row/row.component';
import { NotesComponent } from './components/notes/notes.component';
import { GlobalDownloadButtonComponent } from './components/global-download-button/global-download-button.component';
import { EntityAttachmentsComponent } from './components/entity-attachments/entity-attachments.component';
import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { PendingAttachmentsComponent } from './components/pending-attachments/pending-attachments.component';
import { MonthDayYearPipe } from './pipes/month-day-year.pipe';
import { MonthDayYearTimePipe } from './pipes/month-day-year-time.pipe';
import { CopyComponent } from './components/copy/copy.component';

const pages: any[] = [];

const directives = [
  DragAndDropDirective,
  IsAdminDirective,
  IsCompAssigneeDirective,
  IsCompOfficerDirective,
  IsCompReporterDirective,
  IsSysAdminDirective,
];

const providers = [
  CookieService,
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
  },
  DatePipe,
];

const components = [
  AccentButtonComponent,
  AccentLinkComponent,
  AuthComponent,
  AppBarComponent,
  BusyComponent,
  ButtonComponent,
  CopyComponent,
  DialogComponent,
  DynamicTabsComponent,
  EntityAttachmentsComponent,
  FileUploadComponent,
  FileUploadModalComponent,
  GlobalDownloadButtonComponent,
  InputFilterForm,
  InvitationListComponent,
  InviteComponent,
  JsonDetailComponent,
  LinkComponent,
  LogoComponent,
  MessagesComponent,
  ModalPageComponent,
  NavComponent,
  NotesComponent,
  NotificationsComponent,
  NotificationsIndicatorComponent,
  NotificationListComponent,
  PageComponent,
  PendingAttachmentsComponent,
  PrimaryButtonComponent,
  PrimaryLinkComponent,
  RegisterComponent,
  RouterLinksComponent,
  RowComponent,
  TableComponent,
  ToolbarComponent,
  VersionComponent,
];

const pipes = [
  OptionalDatePipe,
  TitlePipe,
  S3KeyPipe,
  S3NamePipe,
  IdTokenPipe,
  MonthDayYearTimePipe,
  MonthDayYearPipe,
];

const declarations = [...components, ...pages, ...pipes, ...directives];

@NgModule({
  imports: [
    SerializableFormsModule,
    CommonModule,
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations,
  providers,
  exports: [
    ...declarations,
    SerializableFormsModule,
    CommonModule,
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class UiCommonModule {
  static configure(config: Config) {
    return {
      ngModule: UiCommonModule,
      providers: [{ provide: Config, useValue: config }],
    };
  }
}
