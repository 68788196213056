<mat-tab-group>
  <mat-tab
    *ngFor="let kvp of data | keyvalue"
    [label]="kvp.key + ' ' + kvp.value.length"
  >
    <evo-table
      *ngIf="kvp.key"
      [data]="kvp.value"
      [transformers]="transformers"
    ></evo-table>
  </mat-tab>
</mat-tab-group>
