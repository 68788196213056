import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GlobalNote, User } from '@evo/generated/reports';
import { GlobalNotesService } from '../../services/global-notes.service';
import { AppEnum } from '@evo/iso/common';
import { ClaimService } from '../../services/claim.service';
import { IParsedClaims } from '@evo/iso/common';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { filter, first, take, tap } from 'rxjs';

@Component({
  selector: 'evo-notes',
  templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
  @Input() notesSvc: GlobalNotesService;
  @Input() entityId: string;
  @Input() appId: AppEnum;
  @Input() createdById: string;
  @Input() includeAttachments: string;
  noteForm: FormGroup;
  claims: IParsedClaims;
  formData: FormData | undefined;
  files: File[] | undefined;
  busy = true;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private claimsSvc: ClaimService
  ) {}

  async ngOnInit() {
    this.noteForm = this.fb.group({
      text: new FormControl(null, { validators: [Validators.required] }),
    });

    this.claims = this.claimsSvc.get();
    await this.notesSvc.loadNotesForEntity(this.entityId);
    this.busy = false;
  }

  openAttachmentModal() {
    this.dialog
      .open(FileUploadModalComponent, {})
      .afterClosed()
      .pipe(
        filter((output) => !!output?.files?.length),
        take(1),
        tap(({ formData, files }) => {
          this.formData = formData;
          this.files = files;
        })
      )
      .subscribe();
  }

  async postNote({ text }: GlobalNote) {
    const note: GlobalNote = {
      id: undefined as any,
      entityId: this.entityId,
      text,
      createdById: this.createdById,
      createdBy: { id: this.createdById } as User,
      appId: this.appId,
    };

    this.busy = true;

    await this.notesSvc.createNoteWithAttachments(note, this.formData);

    this.formData = undefined;
    this.files = undefined;
    this.noteForm.reset();

    this.busy = false;
  }

  onRemove({ files, formData }: { files: File[]; formData: FormData }) {
    this.formData = formData;
    this.files = files;
  }
}
